
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class extends Vue {
  @Prop() readonly showDialog!: boolean
  @Prop() readonly purchaseDateState!: string
  @Prop() readonly projectId!: string
  @Prop() readonly selectMachinery!: any

  private loading = false
  private machineryData = []
  private machineryName = ''
  private defaultProps= {
    children: 'machineryList',
    label: 'machineryName'
  }

  private defaultExpandedList = [] // 默认展开
  private defaultCheckedList = [] // 默认选中

  private checkList = [] as any
  private checkIdList = [] as any

  @Watch('showDialog')
  onChangeChildValue (newValue: string) {
    // console.log('弹窗状态改变', this.selectMachinery)
    if (newValue) {
      // 每次打开弹框,回显选中的机械
      this.checkList = JSON.parse(JSON.stringify(this.selectMachinery))
      this.checkIdList = this.checkList.map((item: any) => item.machineryId) as any
      // console.log('this.checkIdList', this.checkIdList)
      this.defaultCheckedList = this.checkIdList
      this.defaultExpandedList = this.checkIdList
      this.initData() // 初始化树
    }
  }

  @Watch('machineryName')
  onChangeMachineryName () {
    // console.log('机械名称改变', newValue)
    this.initData()
  }

  initData () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoTree,
      {
        purchaseDateState: this.purchaseDateState,
        projectId: this.projectId,
        machineryName: this.machineryName
      }).then(res => {
      // 有机械才赋值
      let flag = false
      res.forEach((item: any) => {
        if (item?.machineryList?.length) {
          flag = true
        }
      })
      if (flag) {
        this.machineryData = res
      }
    }).finally(() => {
      this.loading = false
    })
  }

  clickTreeNode (clickNode: any, allCheckData: any) {
    console.log(clickNode, allCheckData)
    // console.log('选中', )
    // 判断是不是选中状态
    if (allCheckData.checkedNodes.some((item: any) => item.machineryId === clickNode.machineryId)) {
      // console.log('选中')
      if (!clickNode.machineryType) {
        // console.log('点击父级')
        if (clickNode?.machineryList?.length) {
          // 去重后放到checkList中
          const nowCheckList = clickNode.machineryList.filter((item: any) => {
            return this.checkList.every((k: any) => k.machineryId !== item.machineryId)
          })
          // console.log('clickNode.machineryList', nowCheckList)
          this.checkList = this.checkList.concat(nowCheckList)
          this.checkIdList = this.checkList.map((item: any) => item.machineryId) as any
        }
      } else {
        // console.log('点击子级')
        this.checkList.push(clickNode)
        this.checkIdList.push(clickNode.machineryId)
      }
    } else {
      // console.log('取消选中')
      if (!clickNode.machineryType) {
        // console.log('点击父级')
        this.checkList = this.checkList.filter((item: any) => {
          return clickNode.machineryList.every((k: any) => k.machineryId !== item.machineryId)
        })
        this.checkIdList = this.checkList.map((item: any) => item.machineryId) as any
      } else {
        // console.log('点击子级')
        const index = this.checkList.findIndex((item: any) => {
          return item.machineryId === clickNode.machineryId
        })
        this.checkList.splice(index, 1)
        this.checkIdList = this.checkList.map((item: any) => item.machineryId) as any
      }
    }
  }

  removeMachinery (node: any) {
    // console.log('node', node)
    const index = this.checkList.findIndex((item: any) => {
      return item.machineryId === node.machineryId
    })
    // console.log('索引', index)
    this.checkList.splice(index, 1)
    this.checkIdList = this.checkList.map((item: any) => item.machineryId) as any
    (this.$refs.mytree as any).setChecked(node.machineryId, false)
  }

  addLeft () {
    // console.log('到左侧')
    this.checkList = this.checkList.filter((item: any) => {
      return !this.checkIdList.find((k: any) => item.machineryId === k)
    })
    // console.log('this.checkList', this.checkList)
  }

  addRight () {
    // 只拿机械设备不要机械类型
    let checkedNode = (this.$refs.mytree as any).getCheckedNodes().filter((item: any) => item.machineryType)
    // console.log('checkedNode', checkedNode)
    // 去重
    checkedNode = checkedNode.filter((item: any) => {
      return this.checkList.every((k: any) => k.machineryId !== item.machineryId)
    })
    // console.log('checkedNode', checkedNode)
    this.checkList = this.checkList.concat(checkedNode)
    this.checkIdList = this.checkIdList.concat(checkedNode.map((item: any) => item.machineryId))
  }

  onSave () {
    // console.log('新的', this.checkList)
    // console.log('原本的', this.selectMachinery)
    this.$emit('onCheckList', this.checkList, this.selectMachinery || [])
  }

  closeDialog () {
    // console.log('关闭弹窗')
    this.machineryName = ''
    this.machineryData = []
    this.checkIdList = []
    this.checkList = []
    this.defaultCheckedList = []
    this.defaultExpandedList = []
    this.$emit('update:showDialog', false)
  }
}
