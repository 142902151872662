
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import AddMachinery from './AddMachinery.vue'
@Component({
  components: { AddMachinery },
  name: 'callAdd'
})

export default class ScheduleAdd extends Vue {
  private pickerOptions={
    disabledDate (time: any) {
      const now = (new Date()).getTime()
      const sevenAfter = 7 * 24 * 3600 * 1000
      const res = now + sevenAfter
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() > res
    }
  }

  private loading = false
  private loadingSubmit = false
  private showDialog1 = false
  private userNameList = []
  private projectList= []
  private departmentList = []
  private info= {
    transferCode: '系统自动生成',
    createUserName: '',
    transferDept: '',
    projectId: '',
    transferDate: '',
    userList: []
  }

  private prop = {
    expandTrigger: 'hover',
    value: 'deptId',
    label: 'deptName',
    children: 'childNode',
    emitPath: false
  }

  private rules = {
    transferCode: [
      { required: true, message: '请选择调用单号', trigger: ['blur', 'change'] }
    ],
    createUserName: [
      { required: true, message: '请选择调用申请人', trigger: ['blur', 'change'] }
    ],
    transferDept: [
      { required: true, message: '请选择所属部门', trigger: ['blur', 'change'] }
    ],
    projectId: [
      { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
    ],
    transferDate: [
      { required: true, message: '请选择使用日期', trigger: ['blur', 'change'] }
    ],
    userList: [
      { required: true, message: '请选择调用机械', trigger: ['blur', 'change'] }
    ],
    userId: [
      { required: true, message: '请选择领用人', trigger: ['blur', 'change'] }
    ]
  }

  get transferId () {
    return this.$route.params.transferId
  }

  @Watch('info.projectId')
  projectIdChange (value: any) {
    // console.log('value', value)
    this.getUserList(value)
  }

  created () {
    this.getProjectList()
    this.getDepartmentList()
    // this.getUserList()
    if (this.transferId) {
      this.getMachineryInfo()
      // console.log('created', '编辑')
    } else {
      this.info.createUserName = this.$store.state.userInfo.userName
      this.info.transferDept = JSON.parse(window.sessionStorage.getItem('userInfo') as any).deptId
    }
  }

  getMachineryInfo () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryTransferByTransferId, { transferId: this.transferId }).then((res: any) => {
      this.info = res || []
    }).finally(() => {
      this.loading = false
    })
  }

  getProjectList () {
    // console.log('参数', this.$route.params.transferId)
    this.$axios.get(this.$apis.project.selectYhProjectByList).then(res => {
      this.projectList = res
    })
  }

  getDepartmentList () {
    this.$axios.get(this.$apis.department.departmentList).then((res) => {
      this.departmentList = res || []
    })
  }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.userNameList = res || []
    })
  }

  // 选择项目
  selectProject () {
    // console.log('选择项目')
    this.info.userList = []
  }

  // 选择时间
  selectTransferDate () {
    // console.log('选择时间')
    this.info.userList = []
  }

  onAdd () {
    if (!this.info.projectId || !this.info.transferDate) {
      this.$message.warning('请选择所属项目和使用日期')
      return
    }
    this.showDialog1 = true
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        // console.log('提交数据', this.info)
        const userList = this.info.userList.map((item: any) => {
          return {
            machineryId: item.machineryId,
            userId: item.userId,
            notes: item.notes
          }
        })
        // console.log('userList', userList)
        let params = {}
        let url = ''
        if (this.transferId) {
          // console.log('编辑')
          params = {
            projectId: this.info.projectId,
            transferDept: this.info.transferDept,
            transferDate: this.info.transferDate,
            userList: userList,
            transferId: this.transferId
          }
          url = this.$apis.machineryManage.updateYhMachineryTransfer
        } else {
          // console.log('新增')
          params = {
            projectId: this.info.projectId,
            transferDept: this.info.transferDept,
            transferDate: this.info.transferDate,
            userList: userList
          }
          url = this.$apis.machineryManage.insertYhMachineryTransfer
        }
        // console.log('params', params)
        this.loadingSubmit = true
        this.$axios.post(url, params).then(() => {
          if (this.transferId) {
            this.$message.success('编辑成功')
          } else {
            this.$message.success('新增成功')
          }
          // this.$router.go(-1)
          this.$router.push({ name: 'call' })
        }).finally(() => {
          this.loadingSubmit = false
        })
      }
    })
  }

  onDelete (index: any) {
    this.info.userList.splice(index, 1)
  }

  // data  选中的所有机械
  // data2 上一次选中的机械(不包含新选中的机械)
  onCheckList (data: any, data2: any) {
    // console.log('选中机械成功', data, data2)
    let selectAachinery = data.map((item: any) => {
      // 只重置新添加机械的 userId 和 notes（避免每次新增机械清空之前的机械信息）
      if (!data2.find((k: any) => k.machineryId === item.machineryId)) {
        // console.log('原本没有的机械', item)
        item.userId = '' // userId需要自己选，这里清除掉
        // item.notes = ''
        this.$set(item, 'notes', '')// 不加不可以双向绑定
      }
      return item
    })
    // 去重
    selectAachinery = selectAachinery.filter((item: any) => {
      return this.info.userList.every((k: any) => k.machineryId !== item.machineryId)
    })
    this.info.userList = this.info.userList.concat(selectAachinery)
    // console.log('this.info.userList', this.info.userList)
    this.showDialog1 = false
  }
}
